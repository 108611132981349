import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss";
import { SEND_ENUM } from "../../enums/sendEnum";

const Radio = (props) => {
  return (
    <div>
      <div className={styles.currencyChooseDiv}>
        {props.options.map((RadioItem, i) => (
          <label
            key={i}
            className={
              RadioItem.value === props.value
                ? styles.activeRadioButton
                : styles.radioButton
            }
          >
            <input
              checked={RadioItem.value === props.value}
              type="radio"
              name={props.name}
              value={RadioItem.value}
              className={
                RadioItem.value === props.value
                  ? styles.activeRadioButtonCircle
                  : styles.radioButtonCircle
              }
              onChange={(e) => props.onChangeFunc(e, props)}
            />
            <div className={styles.label}>{RadioItem.label}</div>
          </label>
        ))}
      </div>
      {props.error && (
        <div className={styles.alertSpan}>{SEND_ENUM.errorForRadioButton}</div>
      )}
    </div>
  );
};

Radio.defaultProps = {
  name: "",
  className: "form-check-input",
  outerClassName: "mb-2",
  value: "",
  options: [],
  onChangeFunc: () => {},
  isReq: null,
  onValidateFunc: () => {},
  error: false,
};

Radio.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  outerClassName: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChangeFunc: PropTypes.func,
  isReq: PropTypes.bool,
  error: PropTypes.bool,
  onValidateFunc: PropTypes.func,
};

export default memo(Radio);
