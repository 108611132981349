import { useEffect, useState } from "react";
import { JsonRpcProvider, Contract } from "ethers";

const ERC20_ABI = ["function balanceOf(address owner) view returns (uint256)"];

const useUsdtWalletBalance = (walletAddress) => {
  const [usdtBalance, setUsdtBalance] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBalance = async () => {
      if (!walletAddress) {
        console.log("Invalid wallet address");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        // Connect to Polygon mainnet via public RPC endpoint
        const provider = new JsonRpcProvider("https://rpc.ankr.com/polygon");

        // Connect to the correct USDT contract on Polygon
        const usdtContract = new Contract(
          "0xc2132D05D31c914a87C6611C10748AEb04B58e8F", // USDT contract address on Polygon
          ERC20_ABI,
          provider
        );

        // Fetch the USDT balance as a BigInt
        const balance = await usdtContract.balanceOf(walletAddress);

        // USDT on Polygon typically has 6 decimal places, so we divide by 1e6
        const balanceInUsdt = Number(balance) / 1e6;

        setUsdtBalance(balanceInUsdt);
      } catch (error) {
        console.log("Error fetching USDT balance:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, [walletAddress]);

  return { usdtBalance, loading };
};

export default useUsdtWalletBalance;
