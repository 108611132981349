import React from "react";
import { useContext } from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context";
import { HEADER_ENUM } from "../../enums/headerEnum";
import payezyLogo from "../../assets/radexIcon.svg";
import SupplySummary from "../../pages/SupplySummary/SupplySummary";
import profileImage from "../../assets/RobotAvatar.svg";
import logoutIcon from "../../assets/LogoutIcon.svg";
import styles from "./header.module.scss";
import { radexAuth } from "../../firebase";
import { signOut } from "@firebase/auth";

const Header = ({
  setSendLoginPageNumber,
  setProfileEmail,
  profileEmail,
  setOnClickLoginButton,
  onClickLoginButton,
}) => {
  const { isMobile, setRadexFlow } = useContext(AppContext);
  const navigate = useNavigate();
  const handleClickLogin = () => {
    setSendLoginPageNumber(0);
    setOnClickLoginButton(true);
  };

  // log out function to log the user out of google and set the profile array to null
  const logOut = async () => {
    await signOut(radexAuth);
    setProfileEmail(false);
    setRadexFlow(-2);
    navigate("/");
  };

  return (
    <div
      className={cx(styles.headerMainContainer, {
        [styles.headerMainContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.logoContainer, {
          [styles.logoContainerMob]: isMobile,
        })}
      >
        <img
          src={payezyLogo}
          alt="logo"
          className={cx(styles.fairexLogo, {
            [styles.fairexLogoMob]: isMobile,
          })}
        />

        {!isMobile && <h1 className={styles.radexText}>RADEX</h1>}
      </div>
      {profileEmail && !onClickLoginButton && (
        <div>
          <SupplySummary />
        </div>
      )}
      <div
        className={cx(styles.connectWalletContainer, {
          [styles.connectWalletContainerMob]: isMobile,
        })}
      >
        {!profileEmail && (
          <button
            className={cx(styles.loginButton, {
              [styles.loginButtonMob]: isMobile,
            })}
            onClick={handleClickLogin}
          >
            {HEADER_ENUM.loginButton}
          </button>
        )}
        {profileEmail && !onClickLoginButton && (
          <div className={styles.profileImageContainer}>
            <img
              src={profileImage}
              className={styles.profileImage}
              alt="profile"
            />
          </div>
        )}

        {profileEmail && !isMobile && !onClickLoginButton && (
          <div>
            <div className={styles.userName}>Admin_User12</div>
            <div
              className={cx(styles.profileEmailContainer, {
                [styles.profileEmailContainerMob]: isMobile,
              })}
            >
              <div
                className={cx(styles.profileEmail, {
                  [styles.profileEmailMob]: isMobile,
                })}
              >
                {profileEmail}
              </div>

              <button
                onClick={logOut}
                className={cx(styles.logoutButton, {
                  [styles.logoutButtonMob]: isMobile,
                })}
              >
                <img
                  src={logoutIcon}
                  alt="logout"
                  className={cx(styles.logoutIcon, {
                    [styles.logoutIconMob]: isMobile,
                  })}
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
