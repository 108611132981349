import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import { PZ_KYC_FIX_ENUM } from "../../enums/kycFixEnum.js";
import { getDocs, collection } from "firebase/firestore";
import { payezyFirestore } from "../../firebase";

const KycFix = () => {
  const [kycRejectedUsers, setKycRejectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Fetch the userData collection from Firebase to get user details
  const getPzUsers = async () => {
    try {
      setIsLoading(true);
      const querySnapshot = await getDocs(
        collection(payezyFirestore, "userData")
      );
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({
          id: doc.id, // Document ID
          ...doc.data(), // Spread the document data
        });
      });

      // Filter for rejected users and update rejectedUsers state
      const rejected = userData.filter(
        (user) => user.customer_state === "rejected"
      );
      setKycRejectedUsers(rejected);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPzUsers();
  }, []);
  const handleClickResolve = () => {
    getPzUsers();
  };
  return (
    <div className={styles.kycFixMainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.kycFixHeading}>
          {PZ_KYC_FIX_ENUM.kycFixHeading}
        </div>
        <div className={styles.resolveButtonContainer}>
          <ButtonRade
            customStyling={styles.resolveButton}
            onClick={handleClickResolve}
          >
            {PZ_KYC_FIX_ENUM.resolveButton}
          </ButtonRade>
        </div>
      </div>

      <div className={styles.bodyContainer}>
        {isLoading ? (
          <div className={styles.spinnerOverlay}>
            <div className={styles.spinnerContainer} />
          </div>
        ) : (
          <>
            <div className={styles.headingKeys}>
              <div className={styles.customerGuidKey}>
                {PZ_KYC_FIX_ENUM.guid}
              </div>
              <div className={styles.namekey}>{PZ_KYC_FIX_ENUM.name}</div>
              <div className={styles.createdkey}>{PZ_KYC_FIX_ENUM.created}</div>
              <div className={styles.updatedkey}>{PZ_KYC_FIX_ENUM.updated}</div>
              <div className={styles.kycStatuskey}>
                {PZ_KYC_FIX_ENUM.kycStatus}
              </div>
            </div>
            <div className={styles.valuesContainer}>
              {kycRejectedUsers.map((post) => (
                <div
                  key={post.cybrid_customer_details.customer_guid}
                  className={styles.kycRejectedUsersDetails}
                >
                  <div
                    className={`${styles.customerGuidValue} ${styles.tooltip}`}
                    data-tooltip={post.cybrid_customer_details.customer_guid}
                  >
                    {post.cybrid_customer_details.customer_guid
                      ? `${post.cybrid_customer_details.customer_guid.slice(
                          0,
                          4
                        )}...${post.cybrid_customer_details.customer_guid.slice(
                          -4
                        )}`
                      : "-"}
                  </div>
                  <div className={styles.nameValue}>
                    {post.user_pii?.name?.first ||
                    post.user_pii?.name?.middle ||
                    post.user_pii?.name?.last
                      ? `${post.user_pii.name.first || ""} ${
                          post.user_pii.name.middle || ""
                        } ${post.user_pii.name.last || ""}`.trim()
                      : "-"}
                  </div>
                  <div className={styles.createdValue}>
                    <div className={styles.txFormattedDate}>2024-10-09</div>
                    <div className={styles.txFormattedTime}> T18:40:47 UTC</div>
                  </div>
                  <div className={styles.updatedValue}>
                    <div className={styles.txFormattedDate}>2024-10-09</div>
                    <div className={styles.txFormattedTime}> T18:40:47 UTC</div>
                  </div>

                  <div className={styles.kycStatusValue}>
                    {post.customer_state}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default KycFix;
