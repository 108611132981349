export const PZ_STATES_ENUM = {
  pzStateTitle: "PZ States",
  updateButton: "Update",
  cancelButton: "Cancel",
  openState: "Open",
  closedState: "Closed",
  restrictedState: "Restricted",
  pauseState: "Pause/Maintenance",
  invitationState: "Invitation",
  stateSelectionError: "Please select a state that you want to switch!",
  openDescription:
    "New user sign up is allowed. Normal mode with no restrictions on tranfer request.",
  closedDescription:
    "New user sign up is restricted (Invitations are also blocked). Existing users can log in and make transfer request.",
  restrictedDescription:
    "All transfer requests are restricted. New users and existing users cannot make any transfer request. New user sign up and invitations are allowed in this mode.",
  invitationDescription:
    "Invite only mode - New user sign up is only allowed through invitation. No restrictions on transfer request.",
  updateTreasuryButton: "Update Treasury",
};
