import React, { useState } from "react";
import { PZ_STATES_ENUM } from "../../enums/pzStatesEnum";
import ButtonRade from "../../components/RadeButtons";
import styles from "./index.module.scss";
import { doc, setDoc, serverTimestamp } from "@firebase/firestore";
import { payezyFirestore } from "../../firebase";

const PzStateUpdateModal = ({
  pzStateValue,
  setPzStateValue,
  setModalIsOpen,
  onStateUpdate,
}) => {
  // State to set error message
  const [errorMessage, setErrorMessage] = useState("");
  // Handle cancel btton on click
  const handleOnClickCancel = () => {
    setModalIsOpen(false);
  };
  // Handle update btton on click
  const handleClickUpdate = async () => {
    try {
      // Use the modular syntax to create or update the document in Firestore
      const docRef = doc(payezyFirestore, "pzStates", "currentState");
      await setDoc(docRef, {
        state: pzStateValue, // Set the selected state
        updatedAt: serverTimestamp(), // Add server timestamp
      });

      console.log("State updated to:", pzStateValue);
      onStateUpdate(pzStateValue);
      setPzStateValue(""); // reset the state
      setModalIsOpen(false); // Close the modal after successful update
    } catch (error) {
      console.error("Error updating state:", error);
      setErrorMessage(error);
    }
  };
  return (
    <div>
      <div className={styles.popUpContent}>
        Are you sure you want to switch your App into {pzStateValue} State?
      </div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={styles.cancelButton}
          onClick={handleOnClickCancel}
        >
          {PZ_STATES_ENUM.cancelButton}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={styles.updateButton}
          onClick={handleClickUpdate}
        >
          {PZ_STATES_ENUM.updateButton}
        </ButtonRade>
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export default PzStateUpdateModal;
