import { useContext } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import Card from "../../components/Card/Card";
import { SEND_ENUM } from "../../enums/sendEnum";
import googleIcon from "../../assets/googleIcon.svg";
import {
  radexAuth,
  authProvider,
  payezyAuth,
  tetherxAuth,
} from "../../firebase";
import { signInWithPopup } from "firebase/auth";
import styles from "./index.module.scss";

const LoginWithGoogle = ({ setProfileEmail, setOnClickLoginButton }) => {
  const { isMobile, setSendLoginPageNumber, setLoading } =
    useContext(AppContext);
  // Function that handles the login function
  const login = () => {
    setLoading(true); // Set loading state to true

    signInWithPopup(payezyAuth, authProvider) // Start with payezyAuth
      .then((data) => {
        const { email } = data.user;
        const allowedEmailsPayezy = ["team@rariti.io"];

        if (allowedEmailsPayezy.includes(email)) {
          // Email is correct for payezyAuth, proceed to tetherxAuth
          return signInWithPopup(tetherxAuth, authProvider).then((data) => {
            const { email } = data.user;
            const allowedEmailsTetherX = ["team@rariti.io"];

            if (allowedEmailsTetherX.includes(email)) {
              // Email is correct for tetherxAuth, proceed to radexAuth
              return signInWithPopup(radexAuth, authProvider).then((data) => {
                const { email } = data.user;
                const allowedEmailsRadex = ["team@rariti.io"];

                if (allowedEmailsRadex.includes(email)) {
                  // Email is correct for radexAuth
                  setProfileEmail(email);
                  setSendLoginPageNumber(-1);
                  setOnClickLoginButton(false);
                } else {
                  setProfileEmail(null);
                  setOnClickLoginButton(true);
                  throw new Error("Unauthorized email for radexAuth");
                }
              });
            } else {
              setProfileEmail(null);
              setOnClickLoginButton(true);
              throw new Error("Unauthorized email for tetherxAuth");
            }
          });
        } else {
          setProfileEmail(null);
          setOnClickLoginButton(true);
          throw new Error("Unauthorized email for payezyAuth");
        }
      })
      .catch((error) => {
        console.log("Login Failed:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading state to false after completing the login process
      });
  };

  const CardTitle = () => (
    <>
      <div
        className={cx(styles.loginTitle, {
          [styles.loginTitleMob]: isMobile,
        })}
      >
        {SEND_ENUM.login}
      </div>
    </>
  );

  const CardBody = () => (
    <div>
      <div className={styles.loginUsingGoogleAccount}>
        {SEND_ENUM.loginUsingGoogleAccount}
      </div>

      <div className={styles.continueWithGoogleButtonContainer}>
        <button
          className={cx(styles.continueWithGoogleButton, {
            [styles.continueWithGoogleButtonMob]: isMobile,
          })}
          onClick={() => login()}
        >
          <img src={googleIcon} alt="google" className={styles.googleIcon} />
          {SEND_ENUM.continueWithGoogle}
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <div
        className={cx(styles.loginWithGoogleContainer, {
          [styles.loginWithGoogleContainerMob]: isMobile,
        })}
      >
        {" "}
        <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      </div>
    </div>
  );
};

export default LoginWithGoogle;
