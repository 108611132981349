export const PZ_PR_ENUM = {
  pendingRequestsHeading: "Pending Requests",
  guid: "Guid",
  dateAndTime: "D/T",
  txId: "TID",
  amount: "Amount",
  sender: "Sender",
  beneficiary: "Beneficiary",
  accountNo: "Account No.",
  ifscCode: "IFSC Code",
  resendButton: "Resend",
  cancelButton: "Cancel",
  confirmButton: "Confirm",
  resendConfirmContent: "Are you sure that you want to resend?",
  pendingRequestSentButton: "Request Sent",
};
