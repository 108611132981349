import React, { useState } from "react";
import ButtonRade from "../../components/RadeButtons";
import styles from "./index.module.scss";
import { PZ_PR_ENUM } from "../../enums/pzPendingRequestsEnum";
import cx from "classnames";
import { radexAuth } from "../../firebase";

const ConfirmResendModal = ({ setModalIsOpen, transactionDetails }) => {
  // State to set error message
  const [isLoading, setIsLoading] = useState(false);
  // Handle cancel btton on click
  const handleOnClickCancel = () => {
    setModalIsOpen(false);
  };
  // Handle update btton on click
  const handleClickConfirm = async () => {
    setIsLoading(true);
    try {
      const idToken = await radexAuth.currentUser.getIdToken(true);
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          inr_amount_out: transactionDetails?.inrAmountOut,
          beneficiary_details: {
            phone_number: transactionDetails?.receiverPhone,
            name: transactionDetails?.receiverName,
            account_number: transactionDetails?.receiverBankNumber,
            ifsc_code: transactionDetails?.receiverBankIFSC,
          },
          transaction_id: transactionDetails?.txId,
          person_id: transactionDetails?.personId,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_KIWI_SERVER_URI + `/eko/retry-transaction`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
        // If the message indicates success, store the status in local storage
        if (
          result.message ===
          "Payment request directly sent to beneficiary queue successfully!"
        ) {
          localStorage.setItem(
            `transactionStatus_${transactionDetails?.txId}`,
            "pendingReuestSent"
          );
        }
        setIsLoading(false);
        setModalIsOpen(false);
        console.log("Transaction retried successfully");
      } else {
        console.log("Error in retrying transaction.");
      }
    } catch (error) {
      console.log("Error:", error);
      setIsLoading(false);
      setModalIsOpen(false);
    }
  };
  return (
    <div>
      <div className={styles.popUpContent}>
        {PZ_PR_ENUM.resendConfirmContent}
      </div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={styles.cancelButton}
          onClick={handleOnClickCancel}
        >
          {PZ_PR_ENUM.cancelButton}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={cx(styles.updateButton, {
            [styles.disabledButton]: isLoading,
          })}
          onClick={handleClickConfirm}
          disabled={isLoading}
        >
          {PZ_PR_ENUM.confirmButton}
          {isLoading && (
            <div className={styles.spinnerOverlay}>
              <div className={styles.spinnerContainer} />
            </div>
          )}
        </ButtonRade>
      </div>
    </div>
  );
};

export default ConfirmResendModal;
