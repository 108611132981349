import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { PZ_STATES_ENUM } from "../../enums/pzStatesEnum";
import Card from "../../components/Card/Card";
import Radio from "../../components/RadioButtons/RadioButton";
import ButtonRade from "../../components/RadeButtons";
import Modal from "react-modal";
import PzStateUpdateModal from "../PzStateUpdateModal/PzStateUpdateModal";
import { doc, getDoc } from "@firebase/firestore"; // Import Firestore methods
import { payezyFirestore } from "../../firebase";
import LoadingSpinner from "./LoadingSpinner";
import { radexAuth } from "../../firebase";

const PzStates = () => {
  // State for storing the radio button input
  const [pzStateValue, setPzStateValue] = useState("");
  //state to open and close the modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // State to set error message
  const [errorMessage, setErrorMessage] = useState("");
  // State to store the fetched current state
  const [currentState, setCurrentState] = useState(null);
  // State to track loading
  const [loading, setLoading] = useState(true);
  // Radio button options
  const payezyStatesOptions = [
    { value: PZ_STATES_ENUM.openState, label: PZ_STATES_ENUM.openState },
    { value: PZ_STATES_ENUM.closedState, label: PZ_STATES_ENUM.closedState },
    {
      value: PZ_STATES_ENUM.restrictedState,
      label: PZ_STATES_ENUM.restrictedState,
    },
    {
      value: PZ_STATES_ENUM.pauseState,
      label: PZ_STATES_ENUM.pauseState,
    },
    // {
    //   value: PZ_STATES_ENUM.invitationState,
    //   label: PZ_STATES_ENUM.invitationState,
    // }, // to be implemented later
  ];
  // Fetch current state from Firestore when the component mounts
  useEffect(() => {
    const fetchCurrentState = async () => {
      try {
        const docRef = doc(payezyFirestore, "pzStates", "currentState");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setCurrentState(data.state); // Set the fetched state
        } else {
          console.log("No current state found");
        }
      } catch (error) {
        console.error("Error fetching current state:", error);
      } finally {
        setLoading(false); // Stop loading once the data is fetched
      }
    };

    fetchCurrentState();
  }, []);
  // Function to handle the state update from the modal
  const handleStateUpdate = (updatedState) => {
    setCurrentState(updatedState); // Update current state immediately
  };

  // Function to handle the state switching
  const handleClickPzStateSwitch = (e) => {
    setErrorMessage("");
    setPzStateValue(e.target.value);
  };
  // Handle update button on click
  const handleClickUpdate = () => {
    if (!pzStateValue) {
      setErrorMessage(PZ_STATES_ENUM.stateSelectionError);
    } else {
      setModalIsOpen(true);
    }
  };
  const handleClickUpdateTreasury = async () => {
    try {
      // Obtain ID token
      const idToken = await radexAuth.currentUser.getIdToken(
        /* forceRefresh */ true
      );
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
        },
      };
      const response = await fetch(
        "https://dev-kiwi.payezy.io/eko/check-balance",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch eko wallet balance.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const CardTitle = () => (
    <div className={styles.pzStatesTitle}>{PZ_STATES_ENUM.pzStateTitle}</div>
  );

  const CardBody = () => (
    <div className={styles.pzStatesContainer}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {currentState && (
            <div className={styles.currentStateDisplay}>
              Current State:{" "}
              <strong className={styles.currentState}>{currentState}</strong>
            </div>
          )}
          <div className={styles.radioButtonsContainer}>
            <Radio
              name="payezyStatesOptions"
              value={pzStateValue}
              options={payezyStatesOptions}
              onChangeFunc={handleClickPzStateSwitch}
            />
          </div>
          <div className={styles.descriptionContainer}>
            <div className={styles.openDescription}>
              <div className={styles.stateName}>Open:</div>
              <div className={styles.stateDescription}>
                {PZ_STATES_ENUM.openDescription}
              </div>
            </div>
            <div className={styles.closedDescription}>
              <div className={styles.stateName}>Closed:</div>
              <div className={styles.stateDescription}>
                {PZ_STATES_ENUM.closedDescription}
              </div>
            </div>
            <div className={styles.restrictedDescription}>
              <div className={styles.stateName}>Restricted:</div>
              <div className={styles.stateDescription}>
                {PZ_STATES_ENUM.restrictedDescription}
              </div>
            </div>
            {/* To be implemented later */}
            {/* <div className={styles.invitationDescription}>
              <div className={styles.stateName}>Invitation:</div>
              <div className={styles.stateDescription}>
                {PZ_STATES_ENUM.invitationDescription}
              </div>
            </div> */}
          </div>
          <div className={styles.buttonContainer}>
            <ButtonRade
              customStyling={styles.updateButton}
              onClick={handleClickUpdate}
            >
              {PZ_STATES_ENUM.updateButton}
            </ButtonRade>
          </div>
          {errorMessage && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}
        </>
      )}
    </div>
  );

  return (
    <>
      <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      <div className={styles.updateTrasuryButtonContainer}>
        <ButtonRade
          customStyling={styles.updateTrasuryButton}
          onClick={handleClickUpdateTreasury}
        >
          {PZ_STATES_ENUM.updateTreasuryButton}
        </ButtonRade>
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <PzStateUpdateModal
          setModalIsOpen={setModalIsOpen}
          pzStateValue={pzStateValue}
          setPzStateValue={setPzStateValue}
          onStateUpdate={handleStateUpdate}
        />
      </Modal>
    </>
  );
};

export default PzStates;
