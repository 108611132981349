import React, { useState, useEffect } from "react";
import { HEADER_ENUM } from "../../enums/headerEnum";
import styles from "./index.module.scss";
import { radexAuth } from "../../firebase";
import useUsdtWalletBalance from "../../Hooks/useUsdtWalletBalance/useUsdtWalletBalance";
import Skeleton from "react-loading-skeleton";

const MAX_DECIMAL_PLACE = 2;
const SupplySummary = () => {
  const { usdtBalance, loading } = useUsdtWalletBalance(
    process.env.REACT_APP_TREASURY_WALLET_ADDRESS
  );
  const [ekoWalletBalance, setEkoWalletBalance] = useState(null);

  // Fetching eko wallet balance
  useEffect(() => {
    const getEkoBalance = async () => {
      try {
        // Obtain ID token
        const idToken = await radexAuth.currentUser.getIdToken(
          /* forceRefresh */ true
        );

        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
          },
        };

        const response = await fetch(
          process.env.REACT_APP_KIWI_SERVER_URI + `/eko/check-balance`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch eko wallet balance.");
        }
        const result = await response.json();
        setEkoWalletBalance(
          result.response.amt / 10 ** result.response.decimals
        );
      } catch (error) {
        console.log("Error fetching eko wallet balance:", error);
      }
    };

    if (radexAuth.currentUser) {
      getEkoBalance();
    } else {
      console.log("No authenticated user");
    }
  }, []);

  return (
    <div>
      <div className={styles.PZTransfersContainers}>
        <div>
          <p className={styles.supplyHeadings}>{HEADER_ENUM.payezyBalance}</p>
          {!loading ? (
            <p className={styles.supplyValues}>
              {Number(usdtBalance).toFixed(MAX_DECIMAL_PLACE)}
            </p>
          ) : (
            <Skeleton
              width={95}
              height={17}
              style={{
                display: "block",
                backgroundColor: "rgb(25, 39, 59)",
              }}
            />
          )}
        </div>
        {ekoWalletBalance && (
          <div>
            <p className={styles.supplyHeadings}>{HEADER_ENUM.ekoBalance}</p>
            <p className={styles.supplyValues}>
              {ekoWalletBalance.toFixed(MAX_DECIMAL_PLACE)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupplySummary;
