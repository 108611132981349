import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { PZ_PR_ENUM } from "../../enums/pzPendingRequestsEnum";
import { payezyFirestore, radexAuth } from "../../firebase";
import ButtonRade from "../../components/RadeButtons";
import Modal from "react-modal";
import ConfirmResendModal from "../ConfirmResendModal/ConfirmResendModal";
import { getDoc, doc } from "firebase/firestore";
import cx from "classnames";

const MAX_DECIMAL_PLACE = 2;
const PzPendingRequests = () => {
  const [pendingRequestTransactions, setPendingRequestTransactions] = useState(
    []
  );
  const [senderNames, setSenderNames] = useState({}); // State to hold sender full name
  const [customerGuids, setCustomerGuids] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  // State to open and close the modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [transactionPendingStatus, setTransactionPendingStatus] = useState({});

  const handleClickResend = (transaction) => {
    setSelectedTransaction({
      txId: transaction.tx_id,
      personId: transaction.person_id,
      inrAmountOut: transaction.tx_data.inr_amt_out,
      receiverName: transaction.receiver_data.receiver_name,
      receiverBankIFSC: transaction.receiver_data.receiver_bank_ifsc,
      receiverBankNumber: transaction.receiver_data.receiver_bank_number,
      receiverPhone: transaction.receiver_data.receiver_phone,
    });
    setModalIsOpen(true);
  };
  useEffect(() => {
    const getPendingTransactions = async () => {
      try {
        // Obtain ID token
        const idToken = await radexAuth.currentUser.getIdToken(true);
        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        };
        const response = await fetch(
          process.env.REACT_APP_PAYEZY_SERVER_URI + `/admin-fetch-retry-orders`,
          requestOptions
        );
        if (response.ok) {
          // Request was successful
          const result = await response.json();
          setPendingRequestTransactions(result.transactions);
          // Fetch user names based on the transactions
          const senderName = {};
          const customerGuid = {};
          for (const transaction of result.transactions) {
            const userEmail = transaction.userEmail;
            const { fullName, guid } = await fetchSenderDetails(userEmail);
            senderName[userEmail] = fullName;
            customerGuid[userEmail] = guid;
          }
          setSenderNames(senderName); // Update user names state
          setCustomerGuids(customerGuid); // Update customer GUIDs state
        } else {
          console.log("Error fetching transactions with pending requests");
        }
      } catch (error) {
        console.log(
          "Error fetching transactions with pending requests:",
          error
        );
      }
    };
    const fetchSenderDetails = async (email) => {
      try {
        const userDocRef = doc(payezyFirestore, "userData", email); // Reference to user document
        const userDoc = await getDoc(userDocRef); // Get document

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const { first, middle, last } = userData.user_pii.name; // Get user names
          const customerGuid = userData.cybrid_customer_details.customer_guid; // Get customer GUID
          const fullName = `${first} ${middle} ${last}`.trim(); // Concatenate names
          return { fullName, guid: customerGuid }; // Return both full name and customer GUID
        } else {
          console.log("User data not found");
          return { fullName: "Unknown User", guid: null }; // Return default values if user not found
        }
      } catch (error) {
        console.error("Error fetching user details:", error); // Log the error
        return { fullName: "Error retrieving user", guid: null }; // Return default values on error
      }
    };

    getPendingTransactions();
  }, []);

  // Check local storage for each transaction's pending status
  useEffect(() => {
    const pendingStatus = {};
    pendingRequestTransactions.forEach((transaction) => {
      const status = localStorage.getItem(
        `transactionStatus_${transaction.tx_id}`
      );
      pendingStatus[transaction.tx_id] = status === "pendingReuestSent";
    });
    setTransactionPendingStatus(pendingStatus);
  }, [pendingRequestTransactions]);

  return (
    <>
      <div className={styles.pzPendingRequestsMainContainer}>
        <div className={styles.headerContainer}>
          {PZ_PR_ENUM.pendingRequestsHeading}
        </div>
        <div className={styles.bodyContainer}>
          <div className={styles.headingKeys}>
            <div className={styles.dateAndTimekey}>
              {PZ_PR_ENUM.dateAndTime}
            </div>
            <div className={styles.transferIdkey}>{PZ_PR_ENUM.txId}</div>
            <div className={styles.customerGuidKey}>{PZ_PR_ENUM.guid}</div>
            <div className={styles.senderNamekey}>{PZ_PR_ENUM.sender}</div>
            <div className={styles.beneficiaryNamekey}>
              {PZ_PR_ENUM.beneficiary}
            </div>
            <div className={styles.amountkey}>{PZ_PR_ENUM.amount}</div>
            <div className={styles.accountNokey}>{PZ_PR_ENUM.accountNo}</div>
            <div className={styles.ifscCodekey}>{PZ_PR_ENUM.ifscCode}</div>
            <div className={styles.buttonContainer}></div>
          </div>
          <div className={styles.valuesContainer}>
            {pendingRequestTransactions.map((post) => (
              <div
                key={post.tx_id}
                className={styles.pzPendingRequestsTransactions}
              >
                <div className={styles.dateAndTimeValue}>
                  {post.created_at ? (
                    <>
                      <div className={styles.txFormattedDate}>
                        {new Date(post.created_at).toLocaleDateString("en-US", {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                        })}
                      </div>
                      <div className={styles.txFormattedTime}>
                        {new Date(post.created_at).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </div>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
                <div
                  className={`${styles.transferIdValue} ${styles.tooltip}`}
                  data-tooltip={post.tx_id}
                >
                  {post.tx_id
                    ? `${post.tx_id.slice(0, 4)}...${post.tx_id.slice(-2)}`
                    : "-"}
                </div>
                {/* <div
                  className={`${styles.customerGuidValue} ${styles.tooltip}`}
                  data-tooltip={post.cybrid_customer_guid}
                >
                  {post.cybrid_customer_guid
                    ? `${post.cybrid_customer_guid.slice(
                        0,
                        4
                      )}...${post.cybrid_customer_guid.slice(-2)}`
                    : "-"}
                </div> */}
                <div
                  className={`${styles.customerGuidValue} ${styles.tooltip}`}
                  data-tooltip={customerGuids[post.userEmail]}
                >
                  {customerGuids[post.userEmail]
                    ? `${customerGuids[post.userEmail].slice(
                        0,
                        4
                      )}...${customerGuids[post.userEmail].slice(-2)}`
                    : "-"}
                </div>
                <div className={styles.senderNameValue}>
                  {senderNames[post.userEmail]
                    ? senderNames[post.userEmail]
                    : "-"}
                </div>
                <div className={styles.beneficiaryNameValue}>
                  {post.receiver_data.receiver_name
                    ? post.receiver_data.receiver_name
                    : "-"}
                </div>
                <div className={styles.amountValue}>
                  ₹{" "}
                  {post.tx_data.inr_amt_out
                    ? Number(post.tx_data.inr_amt_out).toFixed(
                        MAX_DECIMAL_PLACE
                      )
                    : "-"}
                </div>
                <div className={styles.accountNoValue}>
                  {post.receiver_data.receiver_bank_number
                    ? post.receiver_data.receiver_bank_number
                    : "-"}
                </div>
                <div className={styles.ifscCodeValue}>
                  {post.receiver_data.receiver_bank_ifsc
                    ? post.receiver_data.receiver_bank_ifsc
                    : "-"}
                </div>
                <div className={styles.resendButtonConatiner}>
                  <ButtonRade
                    customStyling={cx(styles.resendButton, {
                      [styles.disabledButton]: transactionPendingStatus[
                        post.tx_id
                      ]
                        ? true
                        : undefined,
                    })}
                    onClick={() => handleClickResend(post)}
                    disabled={
                      transactionPendingStatus[post.tx_id] ? true : undefined
                    }
                  >
                    {transactionPendingStatus[post.tx_id]
                      ? PZ_PR_ENUM.pendingRequestSentButton
                      : PZ_PR_ENUM.resendButton}
                  </ButtonRade>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ConfirmResendModal
          setModalIsOpen={setModalIsOpen}
          transactionDetails={selectedTransaction}
        />
      </Modal>
    </>
  );
};

export default PzPendingRequests;
