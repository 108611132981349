import { useMemo } from "react";

export const usePagination = ({ totalCount, pageSize, currentPage }) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // If total pages are less than or equal to 3, just return the range [1...totalPageCount]
    if (totalPageCount <= 3) {
      return range(1, totalPageCount);
    }

    // Logic to display 3 pages at a time
    let startPage = Math.max(currentPage - 1, 1); // Start from one page before the current page
    let endPage = Math.min(startPage + 2, totalPageCount); // End at 3 pages after the start page

    // Adjust startPage if endPage is at the end of the totalPageCount
    if (endPage - startPage < 2) {
      startPage = Math.max(endPage - 2, 1);
    }

    return range(startPage, endPage);
  }, [totalCount, pageSize, currentPage]);

  return paginationRange;
};

// Helper function to create a range of page numbers
const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};
