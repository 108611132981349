import React, { useState, useEffect, useMemo, useCallback } from "react";
import styles from "./index.module.scss";
import searchIcon from "../../assets/searchIcon.svg";
import filterIcon from "../../assets/filterIcon.svg";
import ButtonRade from "../../components/RadeButtons";
import { payezyFirestore, radexAuth } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import kycVerifiedIcon from "../../assets/kycVerifiedIcon.svg";
import kycReviewIcon from "../../assets/kycReviewIcon.svg";
import kycRejectedIcon from "../../assets/kycRejectedIcon.svg";
import kycUnverifiedIcon from "../../assets/kycUnverified.svg";
import kycFrozenIcon from "../../assets/kycFrozen.svg";
import kycNotInitializedIcon from "../../assets/kycNotInitializedicon.svg";
import Pagination from "../../components/Pagination/Pagination";
import { PZ_USER_ENUM } from "../../enums/PZUserEnum";
import cx from "classnames";
import ReceiverIcon from "../../assets/recieverIcon.svg";
import USDFlagIcon from "../../assets/USFlag.svg";
import TickIcon from "../../assets/TickIcon";
import CopyIcon from "../../assets/CopyIcon";

const MAX_DECIMAL_PLACE = 2;
const limit = 10; // Items per page
const PZUser = () => {
  const [searchTerm, setSearchTerm] = useState(""); //state to store the serach term
  const [pzUsers, setPzUsers] = useState([]); //state to store the user data
  const [currentPage, setCurrentPage] = useState(1); //state to store the current page for pagination
  const [pageSize, setPageSize] = useState(50); //state to store the page size for pagination
  const [inputValue, setInputValue] = useState(pageSize); // Temporary input value
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [userTransactions, setUserTransactions] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [txCurrentPage, setTxCurrentPage] = useState(1);
  const [cache, setCache] = useState({});
  const [copiedIndex, setCopiedIndex] = useState(null); // Track the index of the currently copied text
  const handleCopyClick = (value, index) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        // Copying to clipboard succeeded
        console.log("Copied to clipboard:", value);
        setCopiedIndex(index); // Set the index of the copied text
        setTimeout(() => {
          setCopiedIndex(false); // Change back to copy icon after a delay
        }, 5000);
      })
      .catch((error) => {
        // Copying to clipboard failed
        console.error("Failed to copy to clipboard:", error);
      });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Allow empty value for temporary clearing of the input
    if (value === "") {
      setInputValue("");
      return;
    }

    const numericValue = parseInt(value, 10);

    // Only update if the input is within the allowed range (1-10)
    if (numericValue > 0 && numericValue <= 50) {
      setInputValue(numericValue);
      setPageSize(numericValue); // Update page size immediately
    }
  };
  // Function to handle the filtering of data using the email
  const filteredData = useMemo(() => {
    return pzUsers.filter(
      (post) =>
        post.id && post.id.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [pzUsers, searchTerm]);

  //Function to handle the page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //Function for pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, pageSize]);

  //Fetch he userData collection from the firebase to fetch the user details
  const getPzUsers = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(payezyFirestore, "userData")
      );
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({
          id: doc.id, // Document ID
          ...doc.data(), // Spread the document data
        });
      });

      setPzUsers(userData);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPzUsers();
  }, []);

  const fetchUserTransactions = useCallback(
    async (page = 1) => {
      // setIsLoading(true);

      // Check if the page is already in cache
      if (cache[page]) {
        setUserTransactions(cache[page].transactions);
        setLastEvaluatedKey(cache[page].lastEvaluatedKey);
        // setIsLoading(false);
        return;
      }

      try {
        const key = page === 1 ? null : lastEvaluatedKey;

        // Obtain ID token
        const idToken = await radexAuth.currentUser.getIdToken(true);
        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        };
        // Include lastEvaluatedKey in the URL if it exists
        const url = new URL(
          process.env.REACT_APP_MANGO_SERVER_URI +
            `/admin-get-user-transfer-history/${selectedUserDetails?.id}`
        );
        url.searchParams.append("limit", limit);
        if (key) {
          url.searchParams.append(
            "lastEvaluatedKey",
            encodeURIComponent(JSON.stringify(key))
          );
        }

        const response = await fetch(url, requestOptions);

        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);

          // Update cache
          setCache((prevCache) => ({
            ...prevCache,
            [page]: {
              transactions: responseData.transactions,
              lastEvaluatedKey: responseData.lastEvaluatedKey,
            },
          }));

          setUserTransactions(responseData.transactions);
          // Parse the lastEvaluatedKey if it's a string
          setLastEvaluatedKey(
            responseData.lastEvaluatedKey
              ? typeof responseData.lastEvaluatedKey === "string"
                ? JSON.parse(responseData.lastEvaluatedKey)
                : responseData.lastEvaluatedKey
              : null
          );
        } else {
          const errorData = await response.json();
          console.log("errorData, purchase details:", errorData);
        }
      } catch (err) {
        console.error("Error fetching user transactions:", err);
      }
      //setIsLoading(false);
    },
    [lastEvaluatedKey, cache, selectedUserDetails]
  );

  useEffect(() => {
    fetchUserTransactions(txCurrentPage);
  }, [selectedUserDetails, txCurrentPage, fetchUserTransactions]);

  const handleNextPage = () => {
    if (lastEvaluatedKey) {
      setTxCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (txCurrentPage > 1) {
      setTxCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleClickUserList = (user) => {
    setSelectedUserDetails(user);
    setTxCurrentPage(1);
    setCache({});
    setCopiedIndex(null);
    setLastEvaluatedKey(null);
    fetchUserTransactions(1);
  };
  // Function that format the epoch time
  function formatEpochTime(epochTime) {
    const date = new Date(epochTime);

    // Define the desired date and time formats
    const dateFormat = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const timeFormat = {
      hour: "2-digit",
      minute: "2-digit",
    };

    // Get the date and time strings in the desired format
    const dateStr = date.toLocaleDateString("en-US", dateFormat);
    const timeStr = date.toLocaleTimeString("en-US", timeFormat);

    // Combine the date and time strings
    const formattedTime = `${dateStr} ${timeStr}`;

    return formattedTime;
  }
  const totalTransferAmount = userTransactions.reduce(
    (acc, transaction) => acc + (transaction.tx_data.usd_amount || 0),
    0
  );
  return (
    <div className={styles.pzUsersMainContainer}>
      <div className={styles.usersList}>
        <div className={styles.headerContainer}>
          <div className={styles.searchBarContainer}>
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
            <img
              src={searchIcon}
              className={styles.searchIcon}
              alt="searchIcon"
            />
          </div>
          <div className={styles.filterButtonContainer}>
            <ButtonRade customStyling={styles.filterButton}>
              <img
                src={filterIcon}
                alt="filterIcon"
                className={styles.filterIcon}
              />
            </ButtonRade>
          </div>
        </div>
        <div className={styles.usersListsCollection}>
          {paginatedData.map((user) => (
            <div
              key={user.id}
              className={cx(styles.usersBasicDetailsContainer, {
                [styles.usersBasicDetailsContainerIfClicked]:
                  selectedUserDetails?.id === user.id,
              })}
              onClick={() => handleClickUserList(user)}
            >
              <div className={styles.userNameAndEmail}>
                <div className={styles.userName}>
                  {`${user?.user_pii?.name?.first || ""} ${
                    user?.user_pii?.name?.middle || ""
                  } ${user?.user_pii?.name?.last || ""}`.trim() || "-"}
                </div>
                <div className={styles.userEmail}>{user.id}</div>
              </div>
              <div className={styles.userKycDetails}>
                <div className={styles.kycStatus}>
                  {PZ_USER_ENUM.kyc}: {user?.customer_state}
                </div>
                <div className={styles.kycStatusIcon}>
                  {user?.customer_state === "Not Initialized" && (
                    <img
                      src={kycNotInitializedIcon}
                      alt="kycNotInitializedIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "unverified" && (
                    <img
                      src={kycUnverifiedIcon}
                      alt="kycUnverifiedIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "verified" && (
                    <img
                      src={kycVerifiedIcon}
                      alt="kycVerifiedIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "reviewing" && (
                    <img
                      src={kycReviewIcon}
                      alt="kycReviewIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "rejected" && (
                    <img
                      src={kycRejectedIcon}
                      alt="kycRejectedIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "frozen" && (
                    <img
                      src={kycFrozenIcon}
                      alt="kycFrozenIcon"
                      className={styles.kycIcon}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.paginationContainer}>
          <div className={styles.search}>
            {PZ_USER_ENUM.show}
            <input
              type="number"
              className={styles.searchPage}
              min={1}
              max={10} // Max limit set to 10
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredData.length}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <div className={styles.usersDetails}>
        {selectedUserDetails && (
          <>
            <div className={styles.usersDetailsMainContainer}>
              <div className={styles.usersDetailsHeader}>
                <div className={styles.userSinceContainer}>
                  <div className={styles.usersDetailsKeys}>
                    {PZ_USER_ENUM.userSince} :
                  </div>
                  <div className={styles.userSinceValue}>
                    {selectedUserDetails?.user_since
                      ? selectedUserDetails?.user_since
                      : "-"}
                  </div>
                </div>
                <div className={styles.userKycDetails}>
                  <div className={styles.kycStatus}>
                    {PZ_USER_ENUM.kyc}: {selectedUserDetails?.customer_state}
                  </div>
                  <div className={styles.kycStatusIcon}>
                    {selectedUserDetails?.customer_state ===
                      "Not Initialized" && (
                      <img
                        src={kycNotInitializedIcon}
                        alt="kycNotInitializedIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {selectedUserDetails?.customer_state === "unverified" && (
                      <img
                        src={kycUnverifiedIcon}
                        alt="kycUnverifiedIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {selectedUserDetails?.customer_state === "verified" && (
                      <img
                        src={kycVerifiedIcon}
                        alt="kycVerifiedIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {selectedUserDetails?.customer_state === "reviewing" && (
                      <img
                        src={kycReviewIcon}
                        alt="kycReviewIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {selectedUserDetails?.customer_state === "rejected" && (
                      <img
                        src={kycRejectedIcon}
                        alt="kycRejectedIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {selectedUserDetails?.customer_state === "frozen" && (
                      <img
                        src={kycFrozenIcon}
                        alt="kycFrozenIcon"
                        className={styles.kycIcon}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.usersEstablishedDetails}>
                <div className={styles.personalDetailsContainer}>
                  <div className={styles.fullNameContainer}>
                    <div className={styles.usersDetailsKeys}>
                      {PZ_USER_ENUM.fullName}
                    </div>
                    <div className={styles.valueAndCopyIconContainer}>
                      <div className={styles.fullNameValue}>
                        {`${selectedUserDetails?.user_pii?.name?.first || ""} ${
                          selectedUserDetails?.user_pii?.name?.middle || ""
                        } ${
                          selectedUserDetails?.user_pii?.name?.last || ""
                        }`.trim() || "-"}
                      </div>
                      {`${selectedUserDetails?.user_pii?.name?.first || ""} ${
                        selectedUserDetails?.user_pii?.name?.middle || ""
                      } ${
                        selectedUserDetails?.user_pii?.name?.last || ""
                      }`.trim() && (
                        <div
                          className={styles.copyImageConatiner}
                          onClick={() => {
                            handleCopyClick(
                              `${
                                selectedUserDetails?.user_pii?.name?.first || ""
                              } ${
                                selectedUserDetails?.user_pii?.name?.middle ||
                                ""
                              } ${
                                selectedUserDetails?.user_pii?.name?.last || ""
                              }`.trim(),
                              0
                            );
                          }}
                        >
                          {copiedIndex === 0 ? (
                            <TickIcon className={styles.copyIcon} />
                          ) : (
                            <CopyIcon className={styles.copyIcon} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.emailContainer}>
                    <div className={styles.usersDetailsKeys}>
                      {PZ_USER_ENUM.email}
                    </div>
                    <div className={styles.valueAndCopyIconContainer}>
                      <div className={styles.usersDetailsValues}>
                        {selectedUserDetails?.id || "-"}
                      </div>
                      {selectedUserDetails?.id && (
                        <div
                          className={styles.copyImageConatiner}
                          onClick={() => {
                            handleCopyClick(selectedUserDetails?.id, 1);
                          }}
                        >
                          {copiedIndex === 1 ? (
                            <TickIcon className={styles.copyIcon} />
                          ) : (
                            <CopyIcon className={styles.copyIcon} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.phoneContainer}>
                    <div className={styles.usersDetailsKeys}>
                      {PZ_USER_ENUM.phone}
                    </div>
                    <div className={styles.usersDetailsValues}>
                      {selectedUserDetails?.user_pii?.phone_number || "-"}
                    </div>
                  </div>
                </div>
                <div className={styles.adddressContainer}>
                  <div className={styles.usersDetailsKeys}>
                    {PZ_USER_ENUM.address}
                  </div>
                  <div className={styles.addressDetails}>
                    <div className={styles.addressDetailsKeys}>
                      {PZ_USER_ENUM.street} :
                    </div>
                    <div className={styles.addressDetailsValues}>
                      {selectedUserDetails?.user_pii?.address?.street || "-"}
                    </div>
                  </div>
                  <div className={styles.addressDetails}>
                    <div className={styles.addressDetailsKeys}>
                      {PZ_USER_ENUM.street2} :
                    </div>
                    <div className={styles.addressDetailsValues}>
                      {selectedUserDetails?.user_pii?.address?.street2 || "-"}
                    </div>
                  </div>
                  <div className={styles.addressDetails}>
                    <div className={styles.addressDetailsKeys}>
                      {PZ_USER_ENUM.subdivision} :
                    </div>
                    <div className={styles.addressDetailsValues}>
                      {selectedUserDetails?.user_pii?.address?.subdivision ||
                        "-"}
                    </div>
                  </div>
                  <div className={styles.addressDetails}>
                    <div className={styles.addressDetailsKeys}>
                      {PZ_USER_ENUM.city} :
                    </div>
                    <div className={styles.addressDetailsValues}>
                      {selectedUserDetails?.user_pii?.address?.city || "-"}
                    </div>
                  </div>
                  <div className={styles.addressDetails}>
                    <div className={styles.addressDetailsKeys}>
                      {PZ_USER_ENUM.countryCode} :
                    </div>
                    <div className={styles.addressDetailsValues}>
                      {selectedUserDetails?.user_pii?.address?.country_code ||
                        "-"}
                    </div>
                  </div>
                  <div className={styles.addressDetails}>
                    <div className={styles.addressDetailsKeys}>
                      {PZ_USER_ENUM.postalCode} :
                    </div>
                    <div className={styles.addressDetailsValues}>
                      {selectedUserDetails?.user_pii?.address?.postal_code ||
                        "-"}
                    </div>
                  </div>
                </div>
                <div className={styles.transferDetailsContainer}>
                  <div className={styles.totalTransfersCountContainer}>
                    <div className={styles.usersDetailsKeys}>
                      {PZ_USER_ENUM.totalTransfersCount}
                    </div>
                    <div className={styles.totalTransfersCount}>
                      {userTransactions.length}
                    </div>
                  </div>
                  <div className={styles.totalTransferAmountContainer}>
                    <div className={styles.usersDetailsKeys}>
                      {PZ_USER_ENUM.totalTransferAmount}
                    </div>
                    <div className={styles.totalTransfersAmount}>
                      ${totalTransferAmount.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.documentDetailsContainer}>
                <div className={styles.emailAndDobContainer}>
                  <div className={styles.customerGuidContainer}>
                    <div className={styles.customerGuidKey}>
                      {PZ_USER_ENUM.customerGuid}
                    </div>
                    <div className={styles.valueAndCopyIconContainer}>
                      <div className={styles.documentDetailsValues}>
                        {selectedUserDetails?.cybrid_customer_details
                          ?.customer_guid || "-"}
                      </div>
                      {selectedUserDetails?.cybrid_customer_details
                        ?.customer_guid && (
                        <div
                          className={styles.copyImageConatiner}
                          onClick={() => {
                            handleCopyClick(
                              selectedUserDetails?.cybrid_customer_details
                                ?.customer_guid,
                              2
                            );
                          }}
                        >
                          {copiedIndex === 2 ? (
                            <TickIcon className={styles.copyIconSmall} />
                          ) : (
                            <CopyIcon className={styles.copyIconSmall} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.dobContainer}>
                    <div className={styles.documentDetailsKeys}>
                      {PZ_USER_ENUM.dateOfBirth} :
                    </div>
                    <div className={styles.documentDetailsValues}>
                      {selectedUserDetails?.user_pii?.date_of_birth || "-"}
                    </div>
                  </div>
                  <div className={styles.cybridEmailContainer}>
                    <div className={styles.documentDetailsKeys}>
                      {PZ_USER_ENUM.emailAddress} :
                    </div>
                    <div className={styles.valueAndCopyIconContainer}>
                      <div className={styles.documentDetailsValues}>
                        {selectedUserDetails?.user_pii?.email_address || "-"}
                      </div>
                      {selectedUserDetails?.user_pii?.email_address && (
                        <div
                          className={styles.copyImageConatiner}
                          onClick={() => {
                            handleCopyClick(
                              selectedUserDetails?.user_pii?.email_address,
                              3
                            );
                          }}
                        >
                          {copiedIndex === 3 ? (
                            <TickIcon className={styles.copyIconSmall} />
                          ) : (
                            <CopyIcon className={styles.copyIconSmall} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.documentTypeAndId}>
                  <div className={styles.customerGuidContainer}>
                    <div className={styles.customerGuidKey}>
                      {PZ_USER_ENUM.documentSubmitted}
                    </div>
                    <div className={styles.documentDetailsValues}>
                      {selectedUserDetails?.user_pii?.document_submitted || "-"}
                    </div>
                  </div>
                  <div className={styles.customerGuidContainer}>
                    <div className={styles.customerGuidKey}>
                      {PZ_USER_ENUM.documentId}
                    </div>
                    <div className={styles.documentDetailsValues}>
                      {selectedUserDetails?.user_pii?.document_id || "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.transferHistoryDetailsContainer}>
                <div className={styles.transferHistoryKey}>
                  {PZ_USER_ENUM.transferHistory}
                </div>
                <div className={styles.userTransactionsContainer}>
                  {userTransactions.map((post, index) => (
                    <div
                      key={post.tx_id}
                      className={styles.userTransferHistoryDetails}
                    >
                      <div className={styles.transferDetails}>
                        <div className={styles.transferIdDetails}>
                          <div className={styles.usersDetailsKeys}>
                            {PZ_USER_ENUM.transferId} :
                          </div>
                          <div className={styles.usersDetailsValues}>
                            {post.tx_id ? post.tx_id : "-"}
                          </div>
                          {post.tx_id && (
                            <div
                              className={styles.copyImageConatiner}
                              onClick={() => {
                                handleCopyClick(
                                  post.tx_id,
                                  `transaction-${index}`
                                );
                              }}
                            >
                              {copiedIndex === `transaction-${index}` ? (
                                <TickIcon className={styles.copyIconSmall} />
                              ) : (
                                <CopyIcon className={styles.copyIconSmall} />
                              )}
                            </div>
                          )}
                        </div>
                        <div className={styles.recipientName}>
                          <img
                            src={ReceiverIcon}
                            alt="ReceiverIcon"
                            className={styles.receiverIcon}
                          />
                          {post.receiver_data.full_name
                            ? post.receiver_data.full_name
                            : "-"}
                        </div>
                      </div>
                      <div className={styles.transferDetailsRight}>
                        <div className={styles.usdAmount}>
                          <img
                            src={USDFlagIcon}
                            alt="USD icon"
                            className={styles.usdFlagIcon}
                          />
                          $
                          {post.tx_data.usd_amount
                            ? Number(post.tx_data.usd_amount).toFixed(
                                MAX_DECIMAL_PLACE
                              )
                            : "-"}
                        </div>
                        <div className={styles.transactionTimestamp}>
                          {post.tx_data.created_at
                            ? formatEpochTime(Number(post.tx_data.created_at))
                            : "-"}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.transferPaginationContainer}>
              <div className={styles.previousButtonContainer}>
                <ButtonRade
                  customStyling={cx(styles.previousButton, {
                    [styles.disabledButton]: txCurrentPage === 1,
                  })}
                  onClick={handlePreviousPage}
                  disabled={txCurrentPage === 1}
                >
                  {PZ_USER_ENUM.previousButton}
                </ButtonRade>
              </div>
              <div className={styles.pageNumber}>Page {txCurrentPage}</div>
              <div className={styles.nextButtonContainer}>
                <ButtonRade
                  customStyling={cx(styles.nextButton, {
                    [styles.disabledButton]: !lastEvaluatedKey,
                  })}
                  onClick={handleNextPage}
                  disabled={!lastEvaluatedKey}
                >
                  {PZ_USER_ENUM.nextButton}
                </ButtonRade>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PZUser;
