import React, { useState } from "react";
import SelectArrow from "../../assets/SelectArrow";
import styles from "./index.scss";
import SelectArrowDown from "../../assets/SelectArrowDown";

const SelectBox = ({
  placeHolder,
  options,
  onChange,
  setSelectedValue,
  selectedValue,
  errorDismissOnclick,
  allowCustomInput = false, // New prop to allow custom input
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
    errorDismissOnclick();
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    return selectedValue;
  };

  const onItemClick = (option) => {
    setSelectedValue(option);
    onChange(option);
    setShowMenu(false);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCustomInput = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (allowCustomInput) {
      // Set selected value to the custom input if allowed
      setSelectedValue({ label: value, value }); // Create an object to represent the custom input
      onChange({ label: value, value }); // Trigger onChange
    }
  };

  return (
    <div className="dropdownContainer">
      <div onClick={handleInputClick} className="dropdownInput">
        <div className={styles.dropdownSelected}>{getDisplay()}</div>
        <div>
          <div className="dropdownTool">
            {!showMenu ? <SelectArrow /> : <SelectArrowDown />}
          </div>
        </div>
      </div>

      {showMenu && (
        <div className="dropdownMenu">
          <input
            type="text"
            value={searchTerm}
            onChange={handleCustomInput} // Change here
            placeholder="Search..."
            className="searchInput" // Style this as needed
          />
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div className="selectOptionsDiv" key={option.value}>
                <div
                  onClick={() => onItemClick(option)}
                  className={`dropdown-item ${
                    selectedValue.value === option.value ? "selected" : ""
                  }`}
                >
                  {option.label}
                </div>
              </div>
            ))
          ) : (
            <div className="noOptions">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectBox;
