export const SEND_ENUM = {
  enterAmountOfUSDWishToSend: "Enter the amount of USD you wish to send. ",
  youSend: "You Send",
  RUD: "RUD",
  recipientGets: "Recipient gets",
  INR: "INR",
  proceedButton: "Proceed",
  newToApp: "New to ",
  XXX: " Payezy?",
  learnMore: "Learn more",
  USD: "USD",
  login: "Login",
  loginUsingGoogleAccount: "Log in quickly using one of your google accounts.",
  continueWithGoogle: "Continue with Google  ",
  fullName: "Full Name",
  nickName: "Nick Name",
  phone: "Phone",
  accountNumber: "Account Number",
  confirmAccountNumber: "Confirm Account Number",
  IFSCCode: "IFSC Code",
  enterTheDetailsOfReciep: "Enter the details of the recipient.",
  transactionSuccesfull: "Transaction Successful",
  pleaseBePatient:
    "Please be patient. Recipient will receive your funds in the next 48 hours. ",
  viewStatus: "Check Status",
  yourRecentTransaction: "Transfer History",
  ID: "ID",
  date: "Date",
  amount: "Amount",
  recipient: "Recipient",
  receivers: "Receives (INR)",
  accNum: "Account Number",
  status: "Status",
  pleaseLoginToContinue: "Please login to continue!",
  transferId: "Transfer ID",
  accountNum: "Account No.",
  Name: "Name",
  pleaseBePatientYourTransfer:
    "Please be patient. Your transfer order will be successfully delivered within 48 hours.",
  pleaseEnterANick: "Please enter a nick name to continue!",
  pleaseEnterAPhone: "Please enter a phone number to continue!",
  pleaseEnterAFullName: "Please enter a full name to continue!",
  pleaseEnterAAccountNumber: "Please enter an account number to continue!",
  pleaseConfirmAccountNumber: "Please confirm your account number to continue!",
  pleaseEnterAnIFSCCode: "Please enter a IFSC code to continue!",
  fee: "Fee",
  receiver: "Receiver",
  checkStatus: "Check status",
  success: "Success",
};
