import React from "react";

import styles from "./index.module.scss";
const SendReplyPopUp = ({
  setModalIsOpen,
  selectedTicketID,
  handleReplyChange,
  handleSaveReply,
}) => {
  return (
    <div className={styles.sendReplyPopUpContainer}>
      <div className={styles.textAreaContainer}>
        <textarea
          className={styles.replyMessage}
          type="text"
          placeholder="Type your reply here"
          onChange={(event) => handleReplyChange(selectedTicketID, event)} // Call the handleReplyChange function
        />
      </div>

      <div className={styles.buttonContainer}>
        <button
          className={styles.sendButton}
          onClick={() => {
            setModalIsOpen(false); // Close the modal
          }}
        >
          Cancel
        </button>
        <button
          className={styles.sendButton}
          onClick={() => {
            handleSaveReply(); // Call the handleSaveReply function
            setModalIsOpen(false); // Close the modal
          }}
        >
          Reply
        </button>
      </div>
    </div>
  );
};

export default SendReplyPopUp;
