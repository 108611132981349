export const HEADER_ENUM = {
  docsButton: "Docs",
  loginButton: "Login",
  totalBuyOrders: "Total Buy Orders",
  totalBuyOrdersValue: "-",
  totalSendOrders: "Total Send Orders",
  totalSendOrdersValue: "-",
  tetherXTreasuryBalance: "INR (Bank) Balance",
  tetherXTreasuryBalanceValue: "10,00,000",
  payezyBalance: "USDT Balance",
  payezyBalanceValue: "10,000",
  ekoBalance: "EKO Balance",
};
